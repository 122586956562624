import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Const from "../../helpers/const";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CourseLesson from './CourseLesson';
import { Fonts, BgColor, Color } from "../../theme";
import { Row, Item } from "@mui-treasury/components/flex";
import { Info } from "@mui-treasury/components/info";
import { useApexInfoStyles } from "@mui-treasury/styles/info/apex";

const styles = (theme) => ({
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});


const useStyles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    marginTop: -150,
    paddingTop: 70,
    paddingBottom: 20,
    width: "auto",
  },
  root: {
    maxWidth: 345,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 140,
  },
  mainHeading: {
    fontSize: "20px",
    fontFamily: Fonts.Medium,
  },
}));


const cardStyles = makeStyles(() => ({
  root: {
    marginRight: 13,
    marginTop: 13,
    height: "100%",
    transition: "0.3s",
    position: "relative",
    // "&:before": {
    //   transition: "0.2s",
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   content: '""',
    //   display: "block",
    //   backgroundColor: "#d9daf1",
    //   borderRadius: "1rem",
    //   zIndex: 0,
    //   bottom: 0,
    // },
    "&:hover": {
      // "&:before": {
      //   bottom: -6,
      // },
      // "& $card": {
      //   boxShadow: "-12px 12px 64px 0 #bcc3d6",
      // },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "10px",
    // boxShadow: "0 6px 20px 0 #dbdbe8",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#fff",
    transition: "0.3s",
    height: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#d4d4d4",

    "&:hover": {
      // boxShadow: "none !important",
      // border: "0 !important",
    },
  },
  logo: {
    width: 48,
    height: 48,
    borderRadius: "0.75rem",
  },
  avatar: {
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  join: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myBlue,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#689CF2",
    },
  },
  delete: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myRed,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    marginRight: "10px",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: BgColor.myDarkRed,
    },
  },
  danger: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    borderRadius: "50px",
    backgroundColor: "#F48FB1",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#ff266f",
    },
  },
}));


const CourseLessonContent = ({id, courseDetail, setOpenLeasonGenerator, openAllCourse, setOpenAllCourse,selectedContent}) => {
  const { t, i18n } = useTranslation();
  const Styles = cardStyles();

  const [loading, setLoading] = useState(false)
  const [allTopics, setTopics] = useState([])


  const getCreatedLeason = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${Const.BASE_URL}get/${id}/subtopics`)
      console.log('response : ', response);
      if(response.status === 200){
        setTopics(response.data.data)
        toast.success(response.data.message,{autoClose: 2000})
      }
    } catch (error) {
      toast.error("Internal Server Error",{autoClose: 2000})
    } finally {
      setLoading(false)
    }
  }

  const handleOpenCourse = async (value) => {
    const content = value?.lesson_plans[0]?.lesson_plan?.content;
    //setSelectedContent(content);
   // setOpenAllCourse(true)
  }

  const handleDeleteTopic = async (id) => {
    console.log('value: ', id);    
    try {
      const response = await axios.delete(
        Const.BASE_URL + `topic/${id}/delete`,
      );
      if(response.status === 200){
        setTopics((prev) => prev.filter( (topic) => topic.topic.lesson_id !== id))
        toast.success('Topic Delete Successfully',{autoClose: 2000})
      }
      
    } catch (error) {
      toast.error("Internal Server Error",{autoClose: 2000})
    }
    
  }


  useEffect( () => {
    getCreatedLeason()
  }, [])

  return (
    <div>
      {
        loading && <span>Loading...</span>
      }
      {(openAllCourse && !loading) ? 
      <Box display="flex" flexDirection="row" flexWrap="wrap" paddingTop={0} bgcolor="background.paper">
        {selectedContent.length > 0 ?
          selectedContent && selectedContent?.map((value, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <div className={Styles.root}>
                <div className={Styles.card}>
                  <Row p={2} gap={2}>
                    <Info position={"middle"} useStyles={useApexInfoStyles} p={0}>
                      <Box
                        fontSize={"18px"}
                        letterSpacing={"0px"}
                        lineHeight={"20px"}
                        fontWeight={"bold"}
                        minHeight={"50px"}
                        fontFamily={Fonts.Medium}
                        color={Color.textMyBlack}
                      >
                        <p>{t("Topic")}:- {value.topic}</p>
                        <p>{t("Content")}:- </p>
                        {
                          value?.subtopics?.map( (subtopic, index) => (
                            <div>
                            <div key={index}>{t("Sub-topic")} :- {t(`${subtopic.subtopic}`)}</div>
                            <p key={index}>{t("Description")} :- {subtopic.description}</p>
                            </div>
                          ))
                        }
                      </Box>
                    </Info>
                  </Row>
                  <Box pb={1} px={2} color={"grey.600"} fontSize={"0.875rem"} fontFamily={"Ubuntu"}></Box>
                  <Row p={2} gap={2} position={"bottom"}>
                    <Item position={"center"}>
                      <Button className={Styles.join} variant={"contained"}  onClick={() => handleDeleteTopic(value?.topic?.lesson_id)} >
                        {t('Open Generated Lessons')}
                      </Button>
                      <Button className={Styles.join} variant={"contained"} onClick={() => handleOpenCourse(value)} >
                        {t('Generate lesson plan')}
                      </Button>
                    </Item>
                  </Row>
                </div>
              </div>
            </Grid>
          ))
        :
        <span>No Subtopic Topic found !</span>
        }
            
        </Box>
        :
        // <CourseCreated id={courseDetail.id} closeOpenCourse={setOpenCourse} courseDetail={courseDetail}/>
        <span>
          {
            selectedContent !== "" &&
            <CourseLesson id={id} closeOpenCourse={setOpenAllCourse} courseDetail={courseDetail} openAllCourse={openAllCourse}  />
          }
        </span>
      }
    </div>
  )
}

export default CourseLessonContent