import axios from "axios";

// 
import axiosOptions from '../../helpers/axiosOptions'
import Const from '../../helpers/const'


const prompt = `user will provide testType, essayTopicOrQuestion and essay itself
i will provide detailed feed back based on following criteria
 
 "evaluationCriteria": [  
    "Task Achievement/Response",  
    "Coherence and Cohesion",  
    "Lexical Resource",  
    "Grammatical Range and Accuracy"  
  ],  

  "exampleSubmission": {  
    "essayTitle": "The Impact of Technology on Education",  
    "essay": "In recent years, technology has revolutionized the field of education. This essay will discuss the various ways in which technology has impacted education and the potential benefits and drawbacks of this transformation.\n\nFirstly, technology has made education more accessible. Online courses and educational platforms allow students from remote areas to access high-quality education. For example, platforms like Coursera and edX offer courses from prestigious universities, enabling students to learn from the best without geographical limitations.\n\nSecondly, technology has enhanced the learning experience. Interactive tools such as virtual labs and simulations provide hands-on experience, making learning more engaging and effective. For instance, medical students can perform virtual surgeries to practice their skills before handling real patients.\n\nHowever, there are also drawbacks to the increased reliance on technology in education. One major concern is the digital divide, where students from low-income families may not have access to the necessary devices and internet connection. This disparity can widen the educational gap between different socio-economic groups.\n\nIn conclusion, while technology has significantly improved the accessibility and quality of education, it is crucial to address the challenges it presents to ensure that all students benefit equally."  
  },  
  "detailedEvaluationAndFeedback": {  
    "taskAchievementResponse": {  
      "score": {  
        "ielts": "7/9",  
        "toefl": "26/30",  
        "cet 4": "80/100",  
        "cet 6": "80/100", 
        "tem 4": "70/100",
        "tem 8": "70/100",  
      },  
      "strengths": [  
        "The essay thoroughly addresses the topic, discussing both benefits and drawbacks of technology in education.",  
        "Clear examples are provided to support the points made."  
      ],  
      "areasForImprovement": [  
        {  
          "issue": "The conclusion is somewhat general.",  
          "example": "it is crucial to address the challenges it presents to ensure that all students benefit equally.",  
          "solution": "Provide specific solutions or strategies to overcome the digital divide. For instance, 'Governments and institutions should invest in infrastructure to provide affordable internet access and devices to underprivileged students.'"  
        }  
      ]  
    },  
    "coherenceAndCohesion": {  
      "score": {  
        "ielts": "7/9",  
        "toefl": "24/30",  
        "cet 4": "75/100",  
        "cet 6": "75/100", 
        "tem 4": "68/100",
        "tem 8": "68/100",  
      },  
      "strengths": [  
        "The essay is well-organized with a logical flow of ideas.",  
        "Effective use of transition words such as 'Firstly,' 'Secondly,' and 'However.'"  
      ],  
      "areasForImprovement": [  
        {  
          "issue": "Some sentences are long and complex, which may affect readability.",  
          "example": "Online courses and educational platforms allow students from remote areas to access high-quality education.",  
          "solution": "Break longer sentences into shorter, clearer ones. For instance, 'Online courses and educational platforms enable learners from remote areas to access high-quality education. This allows them to learn from the best without geographical limitations.'"  
        }  
      ]  
    },  
    "lexicalResource": {  
      "score": {  
        "ielts": "7/9",  
        "toefl": "25/30",  
        "cet 4": "78/100",  
        "cet 6": "78/100", 
        "tem 4": "72/100",
        "tem 8": "72/100", 
      },  
      "strengths": [  
        "Good range of vocabulary and appropriate use of academic terms.",  
        "Words like 'revolutionized,' 'disparity,' and 'engaging' demonstrate a strong lexical resource."  
      ],  
      "areasForImprovement": [  
        {  
          "issue": "Repetitive use of certain words.",  
          "example": "Frequent use of the word 'students.'",  
          "solution": "Use synonyms to add variety. For example, 'students' can be alternated with 'learners,' 'pupils,' or 'scholars.'"  
        }  
      ]  
    },  
    "grammaticalRangeAndAccuracy": {  
      "score": {  
        "ielts": "7/9",  
        "toefl": "24/30",  
        "cet 4": "76/100",  
        "cet 6": "76/100", 
        "tem 4": "70/100",
        "tem 8": "70/100",  
      },  
      "strengths": [  
        "The essay uses a range of sentence structures and maintains grammatical accuracy.",  
        "Proper use of punctuation and conjunctions."  
      ],  
      "areasForImprovement": [  
        {  
          "issue": "Minor grammatical errors and occasional awkward phrasing.",  
          "example": "This disparity can widen the educational gap between different socio-economic groups.",  
          "solution": "Revise for clarity. For instance, 'This disparity can widen the educational gap among various socio-economic groups.'"  
        }  
      ]  
    }  
  },  
  "finalGrade": {  
    "ielts": "7/9",  
    "toefl": "26/30",  
    "cet 4": "77/100",  
    "cet 6": "77/100", 
    "tem 4": "70/100",
    "tem 8": "70/100", 
  },  
  "recommendationsForImprovement": [  
    {  
      "category": "Task Achievement/Response",  
      "recommendation": "Strengthen the conclusion by suggesting specific solutions to the issues discussed."  
    },  
    {  
      "category": "Coherence and Cohesion",  
      "recommendation": "Break up longer sentences for better readability."  
    },  
    {  
      "category": "Lexical Resource",  
      "recommendation": "Incorporate a wider range of vocabulary and avoid repetition."  
    },  
    {  
      "category": "Grammatical Range and Accuracy",  
      "recommendation": "Review and correct minor grammatical errors and awkward phrasing."  
    }  
  ],  
  "exampleOfRevision": {  
    "originalSentence": "Online courses and educational platforms allow students from remote areas to access high-quality education.",  
    "revisedSentence": "Online courses and educational platforms enable learners from remote areas to access high-quality education. This provides an opportunity for students to learn from the best without geographical limitations."  
  }  
  
`

// FUNCTION to Fetch Selected Forum's Question's Answer
export function forumQuestionDetails(questionObject) {
    return async (dispatch) => {

        try {
            // Step:1 Try to fetch answer from backend API
            let data = { 'question_id': questionObject.id }
        
            const response = await axios.post(Const.BASE_URL + 'get-GetAiAnswer', data, axiosOptions(localStorage.userToken));
           

            // Check if API response successfull
            if (response?.data?.message === 'success' && response?.data?.status === 'true') {
                if (
                    // Now check if response.data is null
                    response?.data?.data === null ||
                    response?.data?.ai_answer ===
                    "I'm sorry, I'm not sure what you're asking for. Can you please provide more context or information?"
                ) {
                    // Step:2 Try to fetch answer from Chat GPT
                    
                    const response = await axios.post(
                        'https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview',
                        {
                            stop: null,
                            top_p: 0.95,
                            temperature: 0.7,
                            max_tokens: 4000,
                            presence_penalty: 0,
                            frequency_penalty: 0,
                            messages: [
                        {"role":"user", "content":`testType: ${questionObject.question_type}, Title: ${questionObject.title}, Question: ${questionObject.subject}`}
                    ],
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'api-key': '7327443ae85e44419192e4fb0544d702',
                            },
                        },
                    );
                    // Check if successfull response from ChatGpt 
                    if (response) {
                        const assistantResponse = { 'ai_answer': response.data.choices[0].message.content }
                        dispatch({
                            type: 'FORUM_FEEDBACK_ANSWER',
                            payload: assistantResponse,
                        });
                        //  Save Chat GPT response in backend Database
                        let responseObject = {
                            'user_id': questionObject.created_by,
                            'question_id': questionObject.id,
                            'ai_answer': response.data.choices[0].message?.content,
                        }

                        const responseSaveAPI = await axios.post(Const.BASE_URL + 'save-airesponse', responseObject, axiosOptions(localStorage.userToken),
                        );
                    }
                } else {
                    dispatch({
                        type: 'FORUM_FEEDBACK_ANSWER',
                        payload: response.data.data,
                    });
                }
            }
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

// FUNCTION to Fetch Selected Academic-English Question's Answer
export function academicQuestionDetails(questionObject) {
  

    return async (dispatch) => {

        try {
            // Step:1 Try to fetch answer from backend API
            let data = { 'question_id': questionObject.id}

            const response = await axios.post(Const.BASE_URL + 'get-GetAiAnswer', data, axiosOptions(localStorage.userToken));
           

            // Check if API response successfull
            if (response?.data?.message === 'success' && response?.data?.status === 'true') {
                if (
                    // Now check if response.data is null
                    response?.data?.data === null ||
                    response?.data?.ai_answer ===
                    "I'm sorry, I'm not sure what you're asking for. Can you please provide more context or information?"
                ) {
                    // Step:2 Try to fetch answer from Chat GPT
                  
                    const response = await axios.post(
                        'https://irevu.openai.azure.com/openai/deployments/Irevu-ChatGPT35/chat/completions?api-version=2023-03-15-preview',
                        {
                            stop: null,
                            top_p: 0.95,
                            temperature: 0.7,
                            max_tokens: 2000,
                            presence_penalty: 0,
                            frequency_penalty: 0,
                            messages: [{ "role": "system", "content": prompt },
                            {"role":"user", "content":`testType: ${questionObject.question_type}, essayTopicOrQuestion: ${questionObject.subject}, essay: ${questionObject.description}`}
                        ],
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'api-key': '7327443ae85e44419192e4fb0544d702',
                            },
                        },
                    );
                    // Check if successfull response from ChatGpt 
                    if (response) {
                        const assistantResponse = { 'ai_answer': response.data.choices[0].message.content }
                        dispatch({
                            type: 'ACADEMIC_FORUM_QUESTION_DETAILS',
                            payload: assistantResponse,
                        });
                        //  Save Chat GPT response in backend Database
                        let responseObject = {
                            'user_id': questionObject.created_by,
                            'question_id': questionObject.id,
                            'ai_answer': response.data.choices[0].message?.content,
                        }
                        const responseSaveAPI = await axios.post(Const.BASE_URL + 'save-airesponse', responseObject, axiosOptions(localStorage.userToken),
                        );
                    }
                } else {
                    dispatch({
                        type: 'ACADEMIC_FORUM_QUESTION_DETAILS',
                        payload: response.data.data,
                    });
                }
            }
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

export function forumFeedbackList(data) {
    return async (dispatch) => {
        // try {
        try {
            const response = await axios.post(Const.BASE_URL + 'forum-feedback/get-feedback-list', data, axiosOptions(localStorage.userToken));
            dispatch({
                type: 'FORUM_FEEDBACK_LIST',
                payload: response.data.output,
            });
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

export function forumFeedbackAnswer(data) {
    return async (dispatch) => {
        // try {
        try {
            const response = await axios.post(Const.BASE_URL + 'forum-feedback/add-feedback', data, axiosOptions(localStorage.userToken));
            dispatch({
                type: 'FORUM_FEEDBACK_ANSWER',
                payload: response.data.output,
            });
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

export function forumFeedbackLike(data) {
    return async (dispatch) => {
        // try {
        try {
            const response = await axios.post(Const.BASE_URL + 'forum-feedback/like-feedback', data, axiosOptions(localStorage.userToken));
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}