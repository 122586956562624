import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "axios";
import Const from "../../helpers/const";
import SubTopic from "./CourseSubTopic";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import axiosOptions from "../../helpers/axiosOptions";
import { Fonts, BgColor, Color } from "../../theme";
import { Column, Row, Item } from "@mui-treasury/components/flex";
import { Info, InfoSubtitle, InfoTitle } from "@mui-treasury/components/info";
import { useApexInfoStyles } from "@mui-treasury/styles/info/apex";

const styles = (theme) => ({
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});


const useStyles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    marginTop: -150,
    paddingTop: 70,
    paddingBottom: 20,
    width: "auto",
  },
  root: {
    maxWidth: 345,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 140,
  },
  mainHeading: {
    fontSize: "20px",
    fontFamily: Fonts.Medium,
  },
}));


const cardStyles = makeStyles(() => ({
  root: {
    marginRight: 13,
    marginTop: 13,
    height: "100%",
    transition: "0.3s",
    position: "relative",
    // "&:before": {
    //   transition: "0.2s",
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   content: '""',
    //   display: "block",
    //   backgroundColor: "#d9daf1",
    //   borderRadius: "1rem",
    //   zIndex: 0,
    //   bottom: 0,
    // },
    "&:hover": {
      // "&:before": {
      //   bottom: -6,
      // },
      // "& $card": {
      //   boxShadow: "-12px 12px 64px 0 #bcc3d6",
      // },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "10px",
    // boxShadow: "0 6px 20px 0 #dbdbe8",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#fff",
    transition: "0.3s",
    height: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#d4d4d4",

    "&:hover": {
      // boxShadow: "none !important",
      // border: "0 !important",
    },
  },
  logo: {
    width: 48,
    height: 48,
    borderRadius: "0.75rem",
  },
  avatar: {
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  join: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myBlue,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#689CF2",
    },
  },
  delete: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myRed,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    marginRight: "10px",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: BgColor.myDarkRed,
    },
  },
  danger: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    borderRadius: "50px",
    backgroundColor: "#F48FB1",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#ff266f",
    },
  },
}));


const CourseTopic = () => {
  const classes  = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const Styles = cardStyles();

  const [loading, setLoading] = useState(false)
  const [allLeason, setAllLeason] = useState([])
  const authUser = useSelector((store) => store.auth.user);
  const [selectedContent, setSelectedContent] = useState("");
  const [courses, setCourses] = useState([]);

  const checkActivePlan = async () => {
    try {
      const response = await axios.post(
        Const.BASE_URL + "check_genie_status",
        { user_id: authUser?.id },
        axiosOptions(localStorage.userToken)
      );
      if (response && (response?.data?.status === "false" || response?.data?.data?.price !== "45")) {
        history.push("/subscription");
      } else {
        setLoading(false);
      }
    } catch (error) {
      let err = error.response ? error.response.data : error;
      console.log("Error in checking active plan:", err);
      history.push("/subscription");
    }
  };

  useEffect(() => {
    checkActivePlan();
  }, [authUser]);

  const getCourses = async () => {
    try {
      const response = await axios.get(Const.BASE_URL + "data/user/" + authUser.id);
      console.log("courses>>>", response)
      setCourses(response?.data.data)
    } catch (error) {
      console.log("Error getting courses:", error);
    }
  }

  useEffect(() => {
    getCourses()
  }, [])


  const getCreatedLeason = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${Const.BASE_URL}get/${id}/lessonplans`)
      console.log('response : ', response);
      if(response.status === 200){
        setAllLeason(response.data.data)
        toast.success(response.data.message,{autoClose: 2000})
      }
    } catch (error) {
      toast.error("Internal Server Error",{autoClose: 2000})
    } finally {
      setLoading(false)
    }
  }

  const handleOpenCourse = async (value) => {

    setSelectedContent(value);
    //setOpenAllCourse(true)
  }

  const handleDeleteTopic = async (id) => {
    console.log('value: ', id);    
    try {
      const response = await axios.delete(
        Const.BASE_URL + `topic/${id}/delete`,
      );
      if(response.status === 200){
        setAllLeason((prev) => prev.filter( (topic) => topic.topic.lesson_id !== id))
        toast.success('Topic Delete Successfully',{autoClose: 2000})
      }
      
    } catch (error) {
      toast.error("Internal Server Error",{autoClose: 2000})
    }
    
  }


  useEffect( () => {
    getCreatedLeason()
  }, [])

  console.log("courses>>>>>>>>>>",courses,id)

  return (
    <div>
      <Header history={history} />
      <div style={{height: "200px", "background": "rgb(136, 155, 255)", width: "100%"}}></div>
      <Container maxWidth="xl" className={classes.container + " opened-classes-main"} >
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="row">
        <Button style={{ margin: '10px' }} type='button' variant="contained" color="primary" onClick={()=> {history.goBack()}}><span className="MuiButton-label">{t("Back") }</span></Button>
      </Box>
     <Box flexWrap="wrap" p={3} m={0} bgcolor="background.paper" borderRadius={10} display="flex">
        {courses?.length > 0 ?
          courses && courses?.filter(course => course.id == id).map((value, index) => (

            value?.detailedCourseBreakdown?.weeks?.map( (week, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <div className={Styles.root}>
                <div className={Styles.card}>
                  <Row p={2} gap={2}>
                    <Info position={"middle"} useStyles={useApexInfoStyles} p={0}>
                      <Box
                        fontSize={"18px"}
                        letterSpacing={"0px"}
                        lineHeight={"20px"}
                        fontWeight={"bold"}
                        minHeight={"50px"}
                        fontFamily={Fonts.Medium}
                        color={Color.textMyBlack}
                      >
                        {
                         
                            <div>
                              <h6>week : {index}</h6>
                              <p>{t("Topics Covered")}:</p>
{week.hours.map((hour, index)=>(
   
    <p key={index}> {hour.topic} </p>
  // <div key={index}>{t(`${hour.topic}`)}</div>
))
                            
}
                            </div>
                         
                        }
                      </Box>
                    </Info>
                  </Row>
                  <Box pb={1} px={2} color={"grey.600"} fontSize={"0.875rem"} fontFamily={"Ubuntu"}></Box>
                  <Row p={2} gap={2} position={"bottom"}>
                    <Item position={"center"}>
                      {/* <Button className={Styles.delete} variant={"contained"}  onClick={() => handleDeleteTopic(value?.topic?.lesson_id)} >
                        {t('delete_topic')}
                      </Button> */}
                      <Button className={Styles.join} variant={"contained"} onClick={() => history.push(`/course-creator/topics/subtopics/${id}/${index}`)} >
                        {t('open_topic')}
                      </Button>
                    </Item>
                  </Row>
                </div>
              </div>
            </Grid>
             ))
          ))
        :
        <span>No Topic found !</span>
        }
            
        </Box>
        
      
       </Container>
    </div>
  )
}

export default CourseTopic